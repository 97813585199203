@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100vh;
  background-color: white;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

@layer components {
  .btn-primary {
    @apply bg-primary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary p-2 box-border transition ease-in-out delay-150 hover:shadow-lg duration-300;
  }

  .btn-secondary {
    @apply bg-secondary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary p-2 box-border transition ease-in-out delay-150 hover:shadow-lg duration-300;
  }

  .btn-tertiary {
    @apply bg-tertiary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tertiary p-2 box-border;
  }

  .btn-back {
    @apply text-tertiary rounded-md shadow-sm text-right leading-4 font-medium border border-2 border-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white p-2 box-border hover:bg-tertiary hover:text-white transition ease-in-out delay-150 hover:scale-110 duration-300;
  }

  .text-title {
    @apply font-default tracking-tight text-2xl font-default;
  }
  .text-subtitle {
    @apply font-default font-medium tracking-tight text-lg leading-7;
  }
  .text-content {
    @apply font-default tracking-tight leading-6;
  }

  .gradient-primary {
    @apply bg-gradient-to-r from-primary via-secondary to-tertiary animate-gradient bg-200%;
  }
}
